import axios from "axios";

// const credenciais = {
//     URL_API: "https://api.dev.payhow.com.br/api",
//    URL_HTTP: "https://localhost/payhow-lancamento/public",
//    URL_SHORT: "https://localhost/payhow-lancamento/public",
//    appId: "646972106049068",
//    pathname: "/payhow-lancamento/public/"
// };

const api = axios.create({
  baseURL: "https://api.dev.payhow.com.br/api",
});

// Trocar url do img.js

export default api;
